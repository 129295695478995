import React from 'react';
import { HashLink } from 'react-router-hash-link';
import '../css/Home.css';
import FeaturedService from './FeaturedService';


// Imagenes
import Mineria from '../images/Mineria.jpg';
import Refineria from '../images/Refineria.jpg';
import ImagenCalidad from '../images/Calidad.jpg';
import ImagenIngenieria from '../images/Ingenieria.jpg';
import ImagenLegal from '../images/Legal.jpg';

function Home() {
    return(
        <div className='home-container'>
            <section className='contact-section'>
                <div className='contact-section-logo-container'>
                    <h1>TI</h1>
                    <h2>TRUST & INTEGRITY</h2>
                    <h3>ASESORES NORMATIVOS PARA EMPRESAS</h3>
                </div>
                <div className='contact-section-image-container'>
                    <img src={Mineria} alt='Foto de Dominik Vanyi en Unsplash' className='contact-section-image' />
                    <p className='image-text'>Servicio de Consultoría Legal, Calidad e Ingeniería</p>
                    <div className='contact-button'>
                        <HashLink smooth to='/contact#info' className='button' replace>Contacto</HashLink>
                    </div>
                </div>
            </section>

            <section className='about-section'>
                <h1>Quienes Somos</h1>
                <div className='about-info-container'>
                    <img src={Refineria} alt='Foto de Documerica en Unsplash' className='about-image' />
                    <div className='text-container'>
                        <p className='text'>SOMOS UNA EMPRESA QUE BRINDA UNA AMPLIA GAMA DE SERVICIOS PROFESIONALES CON IMPRONTA INNOVADORA QUE NOS PERMITE LOGRAR LA EVOLUCIÓN Y POSICIONAMIENTO EN EL MERCADO DE TU EMPRESA. NUESTRAS SOLUCIONES ESTÁN FUNDADAS EN LA EXPERIENCIA, PROFESIONALISMO Y CONOCIMIENTO TÉCNICO DE NUESTROS ESPECIALISTAS </p>
                        <div className='about-button'>
                            <HashLink smooth to='/about#policy' className='button' replace>Sobre Nosotros</HashLink>
                        </div>
                    </div>
                </div>
            </section>

            <section className='featured-services-section'>
                <h1>Unidades de Servicios</h1>
                <div className='services-container'>
                    <FeaturedService service={'/services#calidad'} imageName={ImagenCalidad} imageDescription={'Foto de Scott Graham en Unsplash'} name='Calidad' description='Nuestra firma se especializa en implementar normas ISO para optimizar la gestión de organizaciones y laboratorios, garantizando estándares en la certificación de productos y servicios para una operación confiable y competitiva.' />
                    <FeaturedService service={'/services#ingenieria'} imageName={ImagenIngenieria} imageDescription={'Foto de ThisisEngineering RAEng en Unsplash'} name='Ingeniería Integral' description='Nos destacamos en ingeniería de fallas, ensayos, calibraciones y la implementación de normas. Además, ofrecemos servicios de elaboración de procedimientos, manuales y códigos de proceso e inspección. Capacitaciones especializadas.' />
                    <FeaturedService service={'/services#legal'} imageName={ImagenLegal} imageDescription={'Foto de Scott Graham en Unsplash'} name='Area Legal' description='El Asesoramiento Jurídico de nuestros expertos abarca distintas áreas , entre ellas Contratos Societarios , Empresariales , Laborales , Risk Management , Licitaciones Públicas . Implementación de Programas de Integridad. Auditorias en Compliance. 
Derecho Migratorio.' />
                </div>
                <div className='services-button'>
                    <HashLink smooth to='/services#calidad' className='button' replace>Todos los Servicios</HashLink>
                </div>
            </section>
            
        </div>
    );
}

export default Home;