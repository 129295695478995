import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

import '../css/Contact.css';
import { AiOutlineWhatsApp, AiOutlineLinkedin, AiOutlineInstagram, AiOutlineMail } from 'react-icons/ai';

import Header from '../images/header-contacto.jpg';

function Contact() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_mi1wgwq', 'template_gnkkqe9', form.current, {
            publicKey: 'mONW754ZE-HsTqvdZ',
          })
          .then(
            (result) => {
                console.log(result.text);
                alert('Mensaje enviado.');
                e.target.reset();
            },
            (error) => {
                alert('Error al enviar el mensaje.')
                console.log('FAILED...', error.text);
            },
          );
      };

    return(
        <div className='contact-container'>
            <div className='contact-header'>
                <img src={Header} alt='Foto cabecera de contacto' />
            </div>
            <div className='contact' id='info'>
                <div className='contact-details'>
                    <h2>Contacto</h2>
                    <h3>Teléfono de contacto</h3>
                    <p>+54 9 261 5096269</p>
                    <h3>Correos de contacto</h3>
                    <p>ing.gonzaloporta@trustintegrityconsultancy.com</p>
                    <p>trustintegrity2020@gmail.com</p>
                    <h3>Dirección</h3>
                    <p>Pedro Molina 461, Planta baja 13, Mendoza Ciudad</p>
                    <h3>Nuestras redes</h3>
                    <a href='https://www.linkedin.com/in/trust-integrity-54349a25a' target='_blank' rel='noreferrer' className='social-link'><AiOutlineLinkedin className='social-icons' /> <strong>LinkedIn</strong></a>
                    <a href='https://www.instagram.com/trust_integrity' target='_blank' rel='noreferrer' className='social-link'><AiOutlineInstagram className='social-icons' /> <strong>Instagram</strong></a>
                    <a href='https://wa.me/5492615096269' target='_blank' rel='noreferrer' className='social-link'><AiOutlineWhatsApp className='social-icons' /> <strong>Whatsapp</strong></a>
                    <a href='mailto:ing.gonzaloporta@trustintegrityconsultancy.com' rel='noreferrer' target='_blank' className='social-link'><AiOutlineMail className='social-icons' /> <strong>Mail</strong></a>
                </div>
                <div className='form-container'>
                    <h2>Tienes preguntas?</h2>
                    <p>Escríbenos y responderemos tus dudas</p>
                    <form className='mail-form' ref={form} onSubmit={sendEmail}>
                        <input type='mail' name='user_email' required={true} placeholder='Escribe tu correo'></input>
                        <input type='text' name='user_subject' required={true} placeholder='Asunto'></input>
                        <textarea type='text' name='user_message' required={true} placeholder='Escriba su consulta...'></textarea>
                        <input type='submit' value='Enviar' className='form-button' />
                    </form>
                </div>
            </div>

            <div className='map-container'>
                <iframe title='location' className='map' src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d837.5213601073688!2d-68.84806663043611!3d-32.89590919835089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x967e090fcd78c37b%3A0xccee500c27bf199e!2sAv.%20Pedro%20Molina%20461%2C%20M5500%20Mendoza!5e0!3m2!1ses!2sar!4v1705257452287!5m2!1ses!2sar' width='600' height='450' allowFullScreen='' loading='lazy' referrerPolicy='no-referrer-when-downgrade'></iframe>
            </div>

        </div> 
    );
}

export default Contact;