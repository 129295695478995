import React from 'react';

import '../css/About.css';

import Mision from '../images/mision.jpg';
import Vision from '../images/vision.jpg';
import Objetivo from '../images/objetivo.jpg';


function About() {
    return(
        <div className='about-container'>
            <div className='about-intro' id='policy'>
                <h1 className='about-title'>POLITICA DE CALIDAD</h1>
                <p><span>&#10003;</span> El objetivo principal de Trust Integrity es satisfacer las expectativas de sus clientes, aportando un agregado de valor a través de su compromiso con ellos.</p>
                <p><span>&#10003;</span> La calidad de nuestros servicios es consecuencia de la motivación y gestión de su gente.</p>
                <p><span>&#10003;</span> Nuestro sistema de trabajo se basa en la mejora continua.</p>
                <p><span>&#10003;</span> Capacitar a nuestros clientes para que puedan trabajar en procesos seguros y confiables.</p>
                <p><span>&#10003;</span> Incorporar nuevas tecnologías y métodos innovadores de trabajo, de forma que seamos una organización de referencia en el sector.</p>
                <p><span>&#10003;</span> Adaptarnos a las nuevas reglamentaciones técnicas y jurídicas con el fin de que el cliente pueda insertarse en nuevos mercados y exigencia de productos y servicios.</p>
            </div>
            

            <div className='section-container'>
                <h2>MISIÓN</h2>
                <div className='description-container'>
                <img className='about-icons' src={Mision} alt='iconos creados por SBTS2018 - Flaticon'/>
                    <p>Brindar servicios de consultoría en el área legal, calidad e ingeniería con el objetivo de contribuir con el aseguramiento de la calidad de los resultados de nuestros clientes apoyando con nuestra labor a la ejecución de proyectos a nivel nacional e internacional satisfaciendo las necesidades de los clientes.</p>
                </div>
            </div>

            <div className='section-container'>
                <h2>VISIÓN</h2>
                <div className='description-container'>
                    <img className='about-icons' src={Vision} alt='iconos creados por wanicon - Flaticon' />
                    <p>Destacarnos por la excelencia y compromiso en la prestación de nuestros servicios y así proyectarnos al exterior buscando ser líderes en aporte tecnológico mediante ejecución de los servicios que ofrecemos.</p>
                </div>
            </div>

            <div className='section-container'>
                <h2>OBJETIVOS</h2>
                <div className='description-container'>
                    <img className='about-icons' src={Objetivo} alt='iconos creados por wanicon - Flaticon' />
                    <p>Nuestros pilares se basan en la seguridad, una mejora continua del servicio y reducción de los tiempos de parada de nuestros clientes, con la consecuente disminución de costos.
                    <br/><br/>Una formación y capacitación  de los operadores y profesionales permitirá una aplicación eficiente de los mantenimientos preventivos y predictivos en los proceso y etapas de producción.
                    <br/><br/>La constante evolución tecnológica nos ubica a la vanguardia en la utilización de las últimas tecnologías permitiendo optimizar los tiempos de mantenimiento de planta.
                    <br/><br/>Nos especializamos en el asesoramiento a empresas para la industria del gas, petróleo y minería.</p>
                </div>
            </div>
            
        </div>
    )
}

export default About;