import React from 'react';
import { HashLink } from 'react-router-hash-link';
import '../css/FeaturedService.css';

function FeaturedService( { imageName, imageDescription, name, description, service } ) {
    return(
        <div className='service'>
            <h2 className='service-name'>{name}</h2>
            <div className='info-container'>
                <img src={`${imageName}`} alt={imageDescription} className='service-image' />
                <p className='service-description'>{description}</p>
            </div>
            <HashLink smooth to={service} className='button' replace>Ver más</HashLink>
        </div>
    );
}

export default FeaturedService;

/* <a href={service} target='_blank' rel='noreferrer' className='button'>Ver más</a> */