import React from 'react';

import '../css/Services.css';

import { AiTwotoneSetting } from "react-icons/ai";

import Calidad from '../images/servicios-calidad.jpg';
import Ingenieria from '../images/ndt.png';
import Legal from '../images/area-legal.jpg';

function Services() {
    return (
        <div className='services-main-container'>
            <div className='service-section' id='calidad'>
                <h2>Calidad</h2>
                <div className='service-details'>
                    <img src={Calidad} alt='Foto de calidad' />
                    <ul>
                        <li><AiTwotoneSetting className='li-icon'/>Sistemas de gestión para organizaciones  (ISO 9001 - ISO 14001 - ISO 45000 - ISO 19011)</li>
                        <li><AiTwotoneSetting className='li-icon'/>Sistemas de gestión para laboratorios ISO 17025</li>
                        <li><AiTwotoneSetting className='li-icon'/>Certificación de productos, Servicios y procesos ISO 17065</li>
                        <li><AiTwotoneSetting className='li-icon'/>Certificación de organismos de inspección ISO 17020</li>
                        <li><AiTwotoneSetting className='li-icon'/>Compliance Officer - ISO 3700</li>
                        <li><AiTwotoneSetting className='li-icon'/>Auditorías internas</li>
                        <li><AiTwotoneSetting className='li-icon'/>Capacitaciones empresariales presenciales y online</li>
                    </ul>
                </div>
            </div>
            <div className='service-section' id='ingenieria'>
                <h2>Ingeniería Integral</h2>
                <div className='service-details'>
                    <img src={Ingenieria} alt='Foto de ingeniería' />
                    <ul>
                        <li><AiTwotoneSetting className='li-icon'/>Integridad estructural</li>
                        <li><AiTwotoneSetting className='li-icon'/>Ingeniería de fallas</li>
                        <li><AiTwotoneSetting className='li-icon'/>Ensayos y mediciones</li>
                        <li><AiTwotoneSetting className='li-icon'/>NDT</li>
                        <li><AiTwotoneSetting className='li-icon'/>Calibraciones</li>
                        <li><AiTwotoneSetting className='li-icon'/>Implementación de Normas</li>
                        <li><AiTwotoneSetting className='li-icon'/>Elaboración de  Códigos, Manuales de Procesos y Servicios</li>
                        <li><AiTwotoneSetting className='li-icon'/>Capacitaciones presenciales y online</li>
                    </ul>
                </div>
            </div>
            <div className='service-section' id='legal'>
                <h2>Área Legal</h2>
                <div className='service-details'>
                    <img src={Legal} alt='Foto de cumplimiento legal' />
                    <ul>
                        <li><AiTwotoneSetting className='li-icon'/>Risk Management: Estudiamos integralmente los riesgos posibles y sus planes para mitigarlos</li>
                        <li><AiTwotoneSetting className='li-icon'/>Contratos de Obra Pública y Participación Pública Privada</li>
                        <li><AiTwotoneSetting className='li-icon'/>Implementación de Programas de integridad</li>
                        <li><AiTwotoneSetting className='li-icon'/>Elaboración de Manuales y Políticas Antilavado, Anticorrupción</li>
                        <li><AiTwotoneSetting className='li-icon'/>Canales de denuncias</li>
                        <li><AiTwotoneSetting className='li-icon'/>Derecho administrativo público nacional, provincial y municipal</li>
                        <li><AiTwotoneSetting className='li-icon'/>Derecho Notarial, Registral, Societario</li>
                        <li><AiTwotoneSetting className='li-icon'/>Confección de licitaciones públicas</li>
                        <li><AiTwotoneSetting className='li-icon'/>Derecho migratorio</li>
                        <li><AiTwotoneSetting className='li-icon'/>Asesoramiento en la preparación de auditorías internas</li>
                        <li><AiTwotoneSetting className='li-icon'/>Capacitaciones Empresariales presenciales y online</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Services;