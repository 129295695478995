import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import '../css/Navbar.css';

function Navbar() {

    const [isActive, setIsActive] = useState(false);

    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    return(
        <nav className='navbar' >
          <div className='logo'><Link to='/' replace={true}>TI</Link></div>
          <ul className={`nav-links ${isActive ? 'active' : ''}`}>
            <li><Link to='/' replace={true}>Home</Link></li>
            <li><Link to='/about' replace={true}>Sobre Nosotros</Link></li>
            <li><Link to='/services' replace={true}>Servicios</Link></li>
            <li><Link to='/contact' replace={true}>Contacto</Link></li>
          </ul>
          <div className='burger-menu' onClick={toggleMenu}>&#9776;</div>
        </nav>
    );
}

export default Navbar;