import React from 'react';
import { Link } from 'react-router-dom';

import '../css/NotFound.css'

function NotFound() {
    return(
        <div className='error-page-container'>
            <h1 className='error-page-title'>404 - Página no encontrada</h1>
            <p className='error-page-text'>Lo sentimos, la página que estás buscando no existe.</p>
            <p className='error-page-text'><Link to='/'>Volver</Link></p>
        </div>
    );
}

export default NotFound;