import React from 'react';
import { HashLink } from 'react-router-hash-link';
import '../css/Footer.css';
import { AiOutlineWhatsApp, AiOutlineLinkedin, AiOutlineInstagram, AiOutlineMail } from "react-icons/ai";

function Footer() {
    return (
        <footer>
            <div className='footer-logo-container'>
                <p>TRUST & INTEGRITY</p>
                <p>Asesores normativos para empresas</p>
            </div>
            <div className='footer-services'>
                <h6>Servicios</h6>
                <div className='service-modules'>
                    <HashLink smooth to='/services#calidad' replace={true}>Calidad</HashLink>
                    <HashLink smooth to='/services#ingenieria' replace={true}>Ingeniería integral</HashLink>
                    <HashLink smooth to='/services#legal' replace={true}>Area Legal</HashLink>
                </div>
            </div>
            <div className='socials-container'>
                <h6>Nuestras Redes</h6>
                <div className='socials'>
                    <a href='https://www.linkedin.com/in/trust-integrity-54349a25a' target='_blank' rel='noreferrer' className='social-link'><AiOutlineLinkedin className='social-icons' /> <strong>LinkedIn</strong></a>
                    <a href='https://www.instagram.com/trust_integrity' target='_blank' rel='noreferrer' className='social-link'><AiOutlineInstagram className='social-icons' /> <strong>Instagram</strong></a>
                    <a href='https://wa.me/5492615096269' target='_blank' rel='noreferrer' className='social-link'><AiOutlineWhatsApp className='social-icons' /> <strong>Whatsapp</strong></a>
                    <a href='mailto:ing.gonzaloporta@trustintegrityconsultancy.com' rel='noreferrer' target='_blank' className='social-link'><AiOutlineMail className='social-icons' /> <strong>Mail</strong></a>
                </div>
            </div>
                
        </footer>
    );
}

export default Footer;